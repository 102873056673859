<template>
  <v-card-title
    class="d-flex justify-space-between flex-wrap sticky-header-bar mb-6"
  >
    <div>
      {{ $t(title) }} {{ additionalTitle }}
    </div>
    <div class="mt-2 mb-2">
      <v-btn
        v-if="showActionButton"
        dark
        class=""
        @click.prevent="$emit('action')"
      >
        {{ $t('Save') }}
      </v-btn>
      <router-link
        :to="{name: goToLink, params: goToLinkId ? {id: goToLinkId} : {}}"
      >
        <v-btn
          :color="linkButtonColor"
          dark
          outlined
          class="ml-2"
          :disabled="!goToLink"
        >
          {{ $t(buttonText) }}
        </v-btn>
      </router-link>
    </div>
  </v-card-title>
</template>
<script>
export default {
  name: 'StandardCardHeader',
  props: {
    goToLink: {
      type: String,
      default: '',
    },
    goToLinkId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    additionalTitle: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
    linkButtonColor: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
